<template>
    <div>
        <v-row>
            <v-col>
                <v-btn @click="Insert()">เพิ่ม</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table :headers="headers" :items="desserts" class="elevation-1">
                    <template v-slot:item.ac="{ item }">
                        <v-btn fab dark small color="success" v-if="item.UserLevel != 0" @click="NextDetail(item.UserId)">
                            <v-icon dark>fa fa-list</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-btn fab dark small color="lime" @click="Edit(item.UserId)">
                            <v-icon dark>fa fa-pen</v-icon>
                        </v-btn>
                        <v-btn fab dark small color="red" @click="Delete(item.UserId)">
                            <v-icon dark>fa fa-trash-alt</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import axios from "axios";
    export default {
        data() {
            return {
                headers: [
                    {
                        text: "ชื่อผู้ใช้",
                        align: "left",
                        sortable: true,
                        value: "UserName"
                    },
                    {
                        text: "เบอร์โทรศัพท์",
                        align: "left",
                        sortable: true,
                        value: "UserTel"
                    },
                    {
                        text: "สาขา",
                        align: "left",
                        sortable: true,
                        value: "OrgList"
                    },
                    {
                        text: "หมายเหตุ",
                        align: "left",
                        sortable: true,
                        value: "UserRemark"
                    },
                    { width: 50, text: "", value: "ac", sortable: false },
                    { width: 120, text: "", value: "action", sortable: false }
                ],
                desserts: []
            };
        },
        mounted() {
            this.$nextTick(() => {
                if (this.$store.state.User.UserLevel == "M") {
                    this.Select();
                } else {
                    this.$router.push("/Member");
                }
            });
        },
        methods: {
            Select() {
                axios.get("Api/V1/Select/Select/User").then(res => {
                    if (res.status == 200) {
                        this.desserts = res.data;
                    }
                });
            },
            NextDetail(v) {
                this.$router.push("User/Detail?UserId=" + v)
            },
            Edit(v) {
                // this.swal({
                //   text: "ใส่รหัสยืนยัน",
                //   content: "input",
                //   button: {
                //     text: "ตกลง",
                //     closeModal: true
                //   }
                // }).then(name => {
                //   if (name == "boss") {
                this.$router.push("/User/Add?UserId=" + v);
                //   } else if (name != null) {
                //     this.noti.Show("รหัสผิด ติดต่อผู้ดูแลระบบ", "red");
                //   }
                // });
            },
            Delete(v) {
                // this.swal({
                //   text: "ใส่รหัสยืนยัน",
                //   content: "input",
                //   button: {
                //     text: "ตกลง",
                //     closeModal: true
                //   }
                // }).then(name => {
                //   if (name == "boss") {
                axios.delete("/Api/V1/Delete/Delete/User?UserId=" + v).then(res => {
                    if (res.status == 200) {
                        this.Select();
                    }
                });
                //   } else if (name != null) {
                //     this.noti.Show("รหัสผิด ติดต่อผู้ดูแลระบบ", "red");
                //   }
                // });
            },
            Insert() {
                this.$router.push("/user/add");
            }
        }
    };
</script>
<style></style>
